.dependency{
    display: flex;
    /* overflow: hidden; */
    height: auto;
}

.dependency-item{
    padding: 10px;
    width: 80% !important;
    height: auto;
    margin: 10px;
    align-content: center;
}

.dependency-delete-item{
    padding: 10px;
    width: 40;
    height: auto;
    margin: 10px;
    align-content: center;
}