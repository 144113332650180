.carousel-indicators [data-bs-target] {
    width: 10px !important;
    height: 10px !important;
    border: 1px solid transparent !important;
    background-color: rgb(0, 0, 0) !important;
    border-radius: 10px;
  }

  .storyboard{
    margin: 10px 20px;
    padding: 10px;
  }

  .storyboard-header{
    display: flex;
    /* height: 80px; */
    padding: 10px 5px 20px 70px ;
    /* background-color: aqua; */
    margin: 10px;
    clear: both;
    overflow: visible;
  }
  .storyboard-header-item{
    padding: 10px;
    height: 100%;
    /* background-color: beige; */
    align-items: left;
    bottom: 10px;
    /* clear: both; */
    margin: 10px !important;
    overflow: visible;
  }

  .input:focus {
    outline: none !important;
    border:1px solid red;
    box-shadow: 0 0 10px #719ECE;
  }
