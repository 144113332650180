.page-section-container {
  /* display: flex; */
  /* justify-content: space-between; */
  margin: 20px;
  border: 2px solid rgb(255, 72, 0);
  padding: 5px;
  border-radius: 10px;
  
}

.page-section-item{
  margin: 120px;
  border: 2px solid rgb(17, 197, 53);
  padding: 15px;
}

.form-item-header-item{
  margin: 10px;
}

.section-header-container{
  display: flex;

  /* justify-content: space-between; */
}

.section-header{
  padding-left: 20px;
}