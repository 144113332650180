.form-page {
  background-color: rgb(31, 31, 31);
  margin: 30px;
  padding: 30px;
  /*padding: 1rem;*/
  /*margin: 2rem auto;*/
  /* width: 80rem; */
  max-width: 100%;
}


.card {
  border-radius: 12px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
}

.form_page_header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  flex-flow: column-reverse;
  /* justify-content: flex-start; */
  /*justify-content: space-between;*/
  flex: 1;
}




@media (min-width: 580px) {
  .form-page__description {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  .form-page__description h2 {
    font-size: 1.25rem;
  }

  .form-page__price {
    font-size: 1.25rem;
    padding: 0.5rem 1.5rem;
  }
}

.form-page__top{
  display: flex;
  height: 100px;
  justify-content: end;
}

.form-page__top_item{
  margin: 5px;
}

.form-page__section{
  margin: 20px;
  border: 2px solid rgb(222, 226, 223);
  padding-top: 20px;
  border-radius: 5px;
}

.form-item-header-container{
  display: flex;
  min-height: 70px;
}

.form-item-header-elements{
  padding: 20px;
}
