.page-navigator-header{
    display: flex;
    height: 50px;
    /* padding: 10px 5px 20px 70px ; */
    /* background-color: rgb(154, 174, 154); */
    /* margin: 10px; */
    clear: both;
    overflow: visible;
    align-items: center;
    justify-content: center;
  }

  .page-navigator-item{
    /* padding: 0px; */
    height: 75%;
    width: 50px;
    background-color: white;
    color: #93AE1D;
    border: solid 1px 1px;
    border-color: #93AE1D;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    /* bottom: 10px; */
    /* clear: both; */
    margin: 10px !important;
    overflow: visible;
  }

  .page-navigator {
    margin: 10px 20px;
    padding: 10px;
  }

  .page-navigator-item:hover {
    border-color: #d0d0d0;
    background-color: white;
    color: #0c0c0c;
  }   
      
  .page-navigator-item:focus {
    background-color: #93AE1D;
    color: white;
    border-color: white;
  }     
