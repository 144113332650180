.vcenter-item {
  
  display: flex;
  align-items: center;
  margin: 20px;
}
/* Some custom styles to beautify this example */
.wrapper {
  background: #ffffff;
  min-height: 100%;
}
.sidebar {
 
  background: rgb(255, 255, 255);
  
}
.box {
  /* padding: 25px; */
  padding-bottom: 50px;
  background: rgb(238, 238, 238);
  width: 100%;
  height: 100%;
  border: #95a232;
  /* border-width: 20; */
  /* margin: 20px; */
  /* clear:both; */
  /* overflow:auto */
}

.canvas {
  /* display: flex; */
  background: rgb(238, 238, 238);
  width: 100%;
  height: 100%;
  bottom: 0;
  /* overflow:auto */
  /* clear:both; */
}

.canvas-top-box {
  display: flex;
  padding: 20px;
  background: rgb(238, 238, 238);
  justify-content: space-around;
  /* align-items: flex-start; */
  justify-items: flex-start;
}

/* margin: 20px;
  border: 2px solid blue;
  padding: 5px; */

body > #root > div {
  height: 100vh;
}
